import React from 'react';
import Seo from '../../components/layout/SEO';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Layout from '../../components/layout/LayoutInner';
import InnerHead from '../../components/layout/InnerHead';
import Pitch from '../../components/home/Pitch';
import OurCases from '../../components/home/OurCases';
import Partners from '../../components/home/Partners';
import Flowline from '../../components/layout/Flowline';
import Hdivider from '../../components/elements/Hdivider';

export default function index({ data }) {

  const teamPhoto = getImage(data.team);
  // const signaturePng = getImage(data.signature);
  const highlightsBG = getImage(data.bgHighlights);
  const stackedHighlightsBG = [`linear-gradient(rgba(47,47,47,0.85),rgba(47,47,47,0.85))`, highlightsBG];

  return (
    <Layout>
      <Seo
        title="About Us"
        canonical="about/"
      />
      <InnerHead title="About Dreyer Law" caption="When Results Matter" />
      <main className="about-page">
        <BgImage Tag="section" className="about-page--highlights" image={stackedHighlightsBG} preserveStackingContext>
          <div className="about-page--highlights__wrap">
            <div className="about-page--highlights__frame"></div>
          </div>
          <div className="container">
            <div className="about-page--highlights--grid">
              <div className="columnHighlight">
                <div className="columnHighlight--inner">
                  <div className="columnHighlight--wrapper">
                    <div className="columnHighlight--title">
                      <h3>Available</h3>
                    </div>
                    <Flowline />
                    <div className="columnHighlight--content">
                      <p>
                        We provide personal, hands-on representation and maintain constant contact with our clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columnHighlight">
                <div className="columnHighlight--inner">
                  <div className="columnHighlight--wrapper">
                    <div className="columnHighlight--title">
                      <h3>Accomplished</h3>
                    </div>
                    <Flowline />
                    <div className="columnHighlight--content">
                      <p>
                        With a collective 75+ years of experience to draw upon, we have recovered millions of dollars for injury victims.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columnHighlight">
                <div className="columnHighlight--inner">
                  <div className="columnHighlight--wrapper">
                    <div className="columnHighlight--title">
                      <h3>Aggressive</h3>
                    </div>
                    <Flowline />
                    <div className="columnHighlight--content">
                      <p>
                        We thoroughly investigate personal injury cases to provide clients with the aggressive representation they require.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BgImage>
        <section className="about-page--history">
          <div className="container">
            <div className="half">
              <div className="about-page--history__left">
                <GatsbyImage image={teamPhoto} alt="The staff at Dreyer Law" loading="eager" />
              </div>
              <div className="about-page--history__right">
                <Hdivider />
                <div className="about-page--history__title">
                  <h3>Dreyer Law</h3>
                  <h2>Newburgh Personal Injury Attorneys Serving the Hudson Valley</h2>
                </div>
                <div className="about-page--history__description">
                  <p>
                    At Dreyer Law, we understand how serious injury and accident cases can impact your life. We have represented thousands of clients facing difficult personal and legal challenges. In every case, we work diligently to seek the most favorable result possible for our clients, while striving to surpass our clients' expectations for personal service. We provide personal, hands-on representation and maintain constant contact with our clients, something we have heard time and again most other law firms do not necessarily do on a consistent basis. Additionally, we pride ourselves on being results-oriented.
                  </p>
                  {/* <GatsbyImage image={signaturePng} alt="Darryl & Sarah Dreyer" /> */}
                  <div className="about-page--history__description--signature">Darryl & Sarah Dreyer</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <OurCases data={data.allResultsJson} />
        <Partners />
        <section className="about-page--why">
          <div className="container">
            <div className="title">
              <div className="title--sub">
                <span>Committed to your success</span>
                <div className="title--wrapper">
                  <h3>
                    Why Clients Choose Dreyer Law
                  </h3>
                </div>
              </div>
            </div>
            <Flowline />
            <div className="about-page--why__content">
              <p>
                For people injured as a result of an auto accident, construction accident or other accident, or for people arrested for DWI or other criminal charge, we can be reached at any time, 24 hours a day, 7 days a week. We offer home and hospital consultations, in addition to office consultations. We are here to help.
              </p>
              <p>
                We believe every person facing a difficult legal challenge needs and deserves a dedicated advocate and legal champion. We put more than 30 years of combined experience and vast resources to work on behalf of each client we serve.
              </p>
              <div className="half">
                <div className="left">
                  <h3>Thoroughly Prepared</h3>
                  <p>
                    We thoroughly investigate personal injury cases to provide clients with the aggressive representation they require. We prepare meticulously for the possibility of trial in each case we handle. Our thorough trial preparation sends a strong message to the insurance company that we mean business. The result of our preparation is greater leverage in plea negotiations, larger settlements in negotiations, and increased chances of success at trial. We believe strongly that our thorough investigation, trial preparation, and reputation as tough trial lawyers are a decided advantage for anyone who has suffered a serious injury.
                  </p>
                </div>
                <div className="right">
                  <h3>What Makes Our Firm Stand Out?</h3>
                  <ul>
                    <li>
                      <StaticImage src='../../images/icons-checkmark.png' />
                      <span>
                        Complimentary consultations for your convenience
                      </span>
                    </li>
                    <li>
                      <StaticImage src='../../images/icons-checkmark.png' />
                      <span>
                        Available 24/7 to help with your legal concerns
                      </span>
                    </li>
                    <li>
                      <StaticImage src='../../images/icons-checkmark.png' />
                      <span>
                        Make home and hospital visits
                      </span>
                    </li>
                    <li>
                      <StaticImage src='../../images/icons-checkmark.png' />
                      <span>
                        A collective 75+ years of experience to draw upon
                      </span>
                    </li>
                    <li>
                      <StaticImage src='../../images/icons-checkmark.png' />
                      <span>
                        Millions of dollars recovered for injury victims
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>
        <Pitch />
      </main>
    </Layout>
  );
}

export const query = graphql`
  query {
    team: file(relativePath: {eq: "about-dreyer-law.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1.777
          placeholder: BLURRED
        )
      }
    }
    signature: file(relativePath: {eq: "paul.png"}) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
        )
      }
    }
    bgHighlights: file(relativePath: {eq: "shutterstock_376319674.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
    allResultsJson(limit: 3) {
      edges {
        node {
          award
          category
          id
          narrative
        }
      }
    }
  }    
`