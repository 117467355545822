import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import React from 'react'

export default function InnerHead({ title, caption }) {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "patrick-fore-379185-unsplash.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
    }
  `)

  const innerPageBG = getImage(data.file);
  const stackedInnerPageBG = [`linear-gradient(rgba(47,47,47,.7),rgba(47,47,47,.7))`, innerPageBG]
  
  const thisTitle = (title) => {
    return (
      <h1 className="inner-page--title">
        {title}
      </h1>
    )
  }

  const thisCaption = (caption) => {
    return (
      <div className="inner-page--caption">
        {caption}
      </div>
    )
  }


  return (
    <BgImage image={stackedInnerPageBG} loading="eager" className="inner-page" preserveStackingContext>
      <div className="inner-page--header">
        { title ? thisTitle(title) : ``}
        { caption ? thisCaption(caption) : ``}
      </div>
    </BgImage>
  )
}
