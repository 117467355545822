import { Link, graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import React from 'react';
import Flowline from '../layout/Flowline';
import Results, { ResultThumb } from '../elements/carousels/Results';

export default function OurCases({ data }) {

  const backgroundImage = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "shutterstock_303809474.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const ourCasesBG = getImage(backgroundImage.file);
  const stackedOurCasesBG = [`linear-gradient(rgba(255,255,255,.8),rgba(255,255,255,.8))`, ourCasesBG];

  return (
    <BgImage Tag="section" image={stackedOurCasesBG} className="our-cases">
      <div className="container">
        <div className="title">
          <span className="title--sub">
            past successes
          </span>
          <div className="title--wrapper">
            <h3>Our Case Results</h3>
            <span className="flowline"></span>
          </div>
        </div>
        <Flowline />
        <Results>
          {data.edges.map(({ node }) => {
            return <ResultThumb data={node} key={node.id} />
          })}
        </Results>
        <div className="our-cases--more">
          <Link to="/results" className="primary-btn">
            Read More
          </Link>
        </div>
      </div>
    </BgImage>
  );
}