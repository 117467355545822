import React, { useState } from "react"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue)
  const handleChange = e => {
    const thisValue = e.target.value === 'checkbox' ? e.target.checked : e.target.value;
    setValue(thisValue)
  }
  return {
    value,
    onChange: handleChange,
  }  
}

export default function SubscribeForm() {
  const email = useFormInput('');
  const website = useFormInput('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [notification, setNotification] = useState('')

  const emailVal = email.value;
  const websiteVal = website.value;

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!executeRecaptcha) {
      return
    }

    // form field validations
    if (!email.value) {
      return setNotification(`Please provide your email address.`);
    } 

    const token = await executeRecaptcha('subscribe');

    const data = JSON.stringify({
      emailVal,
      websiteVal,
      token
    })

    fetch('/api/v1/contact/subscribe', {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-type': 'application/json'
      },
      body: data
    })
      .then(res => res.json())
      .then(data => {
        setNotification(data.msg)
      })
    
    setNotification('Data Sent')

  }

  return ( 
    <div className="pitch--subscribe-form">
      <div className="pitch--subscribe-form__item">
        <div className="pitch--subscribe-form__wrapper">
          <form onSubmit={handleSubmit}>
            <div className="pitch--email">
              <input type="email" name="email" id="emailSubscribe" placeholder="Your Email Address" {...email} required />
              <input type="text" name="website" id="websiteSubscribe" placeholder="Your Website" {...website} />
            </div>
            <div className="pitch--submit">
              <input type="submit" value="Subscribe" />
            </div>
            <div className="reviewBlock">
              {notification && <span>{notification}</span>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
