import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import React from 'react';
import FaEnvelopeOpen from '../../assets/faEnvelopeOpen.svg';
import FaPhoneAlt from '../../assets/faPhoneAlt.svg';
import Flowline from '../layout/Flowline';
import SubscribeForm from '../elements/SubscribeForm';

export default function Pitch() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "shutterstock_544187893.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      site {
        siteMetadata {
          email
          phone
        }
      }
    }
  `)

  const pitchBG = getImage(data.file);
  const stackedPitchBG = [`linear-gradient(rgba(47,47,47,.85),rgba(47,47,47,.85))`, pitchBG];

  return (
    <BgImage Tag="section" className="pitch" image={stackedPitchBG}>
      <div className="pitch--background-wrap">
        <div className="pitch--background-wrap__frame"></div>
      </div>
      <div className="pitch--content">
        <div className="container">
          <div className="title">
            <div className="title--caption">Aggressive Representation You Can Count On</div>
            <div className="title--wrapper">
              <h3>Let our experienced personal injury attorneys get to work for you</h3>
              <span className="flowline"></span>
            </div>
          </div>
          <div className="textbox">
            <FaEnvelopeOpen />
            <span>
              &nbsp;
              {data.site.siteMetadata.email}
            </span>
            <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</span>
            <FaPhoneAlt />
            <span>
              &nbsp;
              {data.site.siteMetadata.phone}
            </span>
          </div>
          <Flowline />
          <div className="pitch--form">
            <div className="title--wrapper">
              <h3 className="pitch--form__title">
                Subscribe To Our Free Newsletter
              </h3>
              <span className="flowline"></span>
            </div>
          </div>
          <SubscribeForm />
        </div>
      </div>
    </BgImage>
  );
}
