import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import React, { Fragment, useState } from 'react'

export const ResultThumb = ({ width, data }) => {
  const { award, narrative, category } = data;

  const backgroundImage = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "dreyer-law-rear-end-accident-snow-2.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: {
              duotone: {
                highlight: "#ffffff"
                shadow: "#b1976b"
              }
            }
          )
        }
      }
    }
  `)

  const cardBG = getImage(backgroundImage.file);
  const stackedCardBG = [`linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.5))`, cardBG];
  const stackedHoverCardBG = [`linear-gradient(rgba(255,255,255,.65),rgba(255,255,255,.65))`, cardBG];

  const stackedCardBGStyle = {
    position: 'absolute'
  };

  return (
    <div className="carousel--item" style={{ width: width }}>
      <div className="results--thumb">
        <div className="results--image">
          <div className="results--image-frame">
            <div className="caseCard">
              <div className="caseCard--title">
                <h3>
                  {award}
                </h3>
                <div className="caseCard--title__caption">
                  {category}
                </div>
              </div>
              <BgImage className="caseCard--hover" image={stackedHoverCardBG} preserveStackingContext style={stackedCardBGStyle}>
                <p>
                  {narrative}
                </p>
              </BgImage>
              <BgImage className="caseCard--background" image={stackedCardBG} preserveStackingContext style={stackedCardBGStyle}>
                {/* Background Image */}
                <div className="caseCard--background__title">
                  <h3>
                    {award}
                  </h3>
                  <div className="caseCard--background__title__caption">
                    {category}
                  </div>
                </div>
              </BgImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Results = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = React.Children.count(children) - 1;
    }

    setActiveIndex(newIndex);
  };

  return (
    <Fragment>
      <div className="results">
        <div className="carousel">
          <div
            className="carousel--inner"
            style={{ transform: `translateX(-${activeIndex * 33}%)` }}
          >
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { width: "33%" });
            })}
          </div>
          <div className="carousel--indicators">
            {React.Children.map(children, (child, index) => {
              return (
                <button
                  className={`${index === activeIndex ? "active" : ""}`}
                  onClick={() => {
                    updateIndex(index);
                  }}
                >
                  <span>{index + 1}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
      <div className="results--mobile">
        <div className="carousel">
          <div
            className="carousel--inner"
            style={{ transform: `translateX(-${activeIndex * 100}%)` }}
          >
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { width: "100%" });
            })}
          </div>
          <div className="carousel--indicators">
            {React.Children.map(children, (child, index) => {
              return (
                <button
                  className={`${index === activeIndex ? "active" : ""}`}
                  onClick={() => {
                    updateIndex(index);
                  }}
                >
                  <span>{index + 1}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
      <div className="results--tablet">
        <div className="carousel">
          <div
            className="carousel--inner"
            style={{ transform: `translateX(-${activeIndex * 45}%)` }}
          >
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { width: "45%" });
            })}
          </div>
          <div className="carousel--indicators">
            {React.Children.map(children, (child, index) => {
              return (
                <button
                  className={`${index === activeIndex ? "active" : ""}`}
                  onClick={() => {
                    updateIndex(index);
                  }}
                >
                  <span>{index + 1}</span>
                </button>
              )
            })}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Results;