import React from 'react'

export default function Flowline() {
  return (
    <div className="flowline">
      <div className="flowline--item">
        {/* Vertical Line */}
      </div>
    </div>
  )
}
