import React from 'react';

export default function Hdivider() {
  return (
    <div class="element--hdivider">
      <div class="item">
        <div class="divider">
          <div class="divider--line"></div>
        </div>
      </div>
    </div>
  );
}
